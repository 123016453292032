import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ErrorIcon from "@mui/icons-material/Error";
import Page from "../layout/Page";
import axios from "axios";
import styles from "./Products.module.css";
import config from "../../config/config.js";

const Products = () => {
  const urlApi = config.api;
  const { shop, name } = useParams();
  const [store, setStore] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedAction, setSelectedAction] = useState({});
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedExplication, setSelectedExplication] = useState({});
  const [warrantyAction, setWarrantyAction] = useState({});
  const [change, setChange] = useState({});
  const [credit, setCredit] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const order = location.state.order || "";
  const products = location.state.order.line_items || "";
  const customer = location.state.order.customer;

  useEffect(() => {
    const bodyHeight = document.body.offsetHeight;
    window.parent.postMessage(bodyHeight, "*");
  }, [
    selectedAction,
    selectedReason,
    change,
    credit,
    message,
    selectedExplication,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const aux = await (
          await axios.get(`${urlApi}/stores/${shop}`)
        ).data.store;
        setStore(aux || {});

        setLoading(false);
      } catch (error) {
        setMessage(error.response?.data?.message);
      }
    };

    fetchData();
  }, [shop, urlApi]);

  const optionsChange = store?.customFields?.reasonChange;
  const optionsDevolution = store?.customFields?.reasonDevolution;
  const optionsWarranty = store?.customFields?.reasonWarranty;

  const findVariantId = (product, skuId) => {
    const values = Object.values(change[skuId]);
    const titleChange = values.join(" / ");
    const selectVariant = product?.variants.find(
      (e) => e.variant.title === titleChange
    )?.variant;

    return selectVariant?.id;
  };

  const selectedProductsFunc = () => {
    let items = [];
    products.forEach((elem) => {
      for (let i = 0; i < elem?.quantity; i++) {
        let filteredVariants = elem.variants.filter((variant) => variant.variant.id === elem.variant_id);
        filteredVariants = filteredVariants[0].variant
        let newVariantId = null;
        if (change[`${filteredVariants.sku}-${i}`]) {
          newVariantId = findVariantId(elem, `${filteredVariants.sku}-${i}`);
        } else if (elem.variants.length === 1 && selectedAction[`${filteredVariants?.sku}-${i}`] === "Troca") {
          newVariantId = elem?.variants?.[0]?.variant?.id;
        } else if (selectedAction[`${filteredVariants.sku}-${i}`] === "Garantia" && warrantyAction[`${filteredVariants?.sku}-${i}`] === "Troca") {
          newVariantId = elem?.variant_id;
        }

        elem.price = parseFloat(elem?.price);

        if (
          selectedAction[`${filteredVariants.sku}-${i}`] !== "Manter" &&
          selectedAction[`${filteredVariants.sku}-${i}`] !== undefined &&
          selectedAction[`${filteredVariants.sku}-${i}`] !== null &&
          selectedAction[`${filteredVariants.sku}-${i}`] !== ""
        ) {
          
          items.push({
                  sku: filteredVariants.sku,
                  skuId: `${filteredVariants.sku}-${i}`,
                  action: selectedAction[`${filteredVariants.sku}-${i}`],
                  reason: selectedReason[`${filteredVariants.sku}-${i}`],
                  explication: selectedExplication[`${filteredVariants.sku}-${i}`],
                  price: Number(elem.price),
                  src: elem.images[0]?.src, // ARRUMAR NO BACK END
                  newOrderVariantId: newVariantId,
                  credit: credit[`${filteredVariants.sku}-${i}`],
                  variantId: filteredVariants.id,
                  productId: filteredVariants.product_id,
                  actionWarranty: warrantyAction[`${filteredVariants.sku}-${i}`],
                  grams: filteredVariants.grams,
                  dimensions: {
                    length: elem?.length,
                    width: elem?.width,
                    height: elem?.height,
                  }
            });
        }
      }
    });
    return items;
  };

  const selectedProducts = selectedProductsFunc();

  const totalInfo = {
    orderId: order.id,
    selectedProducts: selectedProducts,
    customer: customer,
    shipping_address: order.shipping_address,
    billing_address: order.billing_address,
    shipping_reverse_free: order.shipping_reverse_free,
    carrier: order?.shipping_lines[0]?.title
  };

  const generateSelects = (options, sku, variants) => {
    let response = [];
    let res = [];
    for (let elem in variants) {
      const inventory = variants[elem].variant;
      if (inventory?.inventory_quantity > 0) {
        res.push(inventory.title.split(" / "));
      }
    }

    let i = 0;
    const changeSku = change[sku] || [];
    res = res.filter((elem) => {
      if (changeSku) {
        if (
          (elem[0] === changeSku[0] || !changeSku[0]) &&
          (elem[1] === changeSku[1] || !changeSku[1]) &&
          (elem[2] === changeSku[2] || !changeSku[2])
        )
          return elem;
      }
    });
    for (let item in options) {
      const optionsSelect = options[item].map((opt) => {
        return (
          <option
            disabled={res.find((el) => el[i] === opt) ? false : true}
            key={opt.id}
            value={opt}
          >
            {opt} 
          </option>
        );
      });
      response.push(
        <Grid item xs={12} sm={6}>
          <p style={{ textTransform: "capitalize" }}>{item}</p>
          <FormControl fullWidth>
            <div className={styles.changeSelect}>
            <select
              endAdornment={
                change[sku]?.[i] && (
                  <div
                    className={styles.excludeButton}
                    id={i}
                    onClick={(e) => {
                      setChange({
                        ...change,
                        [sku]: {
                          ...change[sku],
                          [e.target.id]: null,
                        },
                      });
                    }}
                  >
                    X
                  </div>
                )
              }
              name={i}
              value={change[sku]?.[i] || ""}
              required
              onChange={(e) => {
                setChange({
                  ...change,
                  [sku]: {
                    ...change[sku],
                    [e.target.name]: e.target.value,
                  },
                });
              }}
            >
              <option selected disabled value=''>
                Selecione uma opção
              </option>
              {optionsSelect} 
            </select>
            {change[sku]?.[i] && (
                  <div
                    className={styles.excludeButton}
                    id={i}
                    onClick={(e) => {
                      setChange({
                        ...change,
                        [sku]: {
                          ...change[sku],
                          [e.target.id]: null,
                        },
                      });
                    }}
                  >
                    X
                  </div>
                )
              }
              </div>
          </FormControl>
        </Grid>
      );

      i++;
    }

    return response;
  };

  const isContinueButtonEnabled = () => {
    const hasSelectedItems = selectedProducts?.length > 0;
    const hasSelectedActionAndReason = selectedProducts?.every((option) => {
      const hasActionAndReason = option.action && option.reason;
      if (option.action === "Troca" && !option.credit) {
        return hasActionAndReason && option.newOrderVariantId;
      } else {
        return hasActionAndReason;
      }
    });

    return hasSelectedItems && hasSelectedActionAndReason;
  };

  useEffect(() => {
    const shouldButtonBeEnabled = isContinueButtonEnabled();
    setIsButtonDisabled(!shouldButtonBeEnabled);
  }, [selectedAction, selectedReason, change, isButtonDisabled]);

  const hasDevolution = selectedProducts.some((product) => {
    return selectedAction[product.skuId] === "Devolução" && !product.credit;
  });

  const hasWarranty = selectedProducts.some((product) => {
    return (
      selectedAction[product.skuId] === "Garantia" &&
      !product.credit &&
      !product.newOrderVariantId
    );
  });

  return (
    <Page>
      {message ? (
        <div className={styles.box}>
          <div className={styles.div_error}>
            <ErrorIcon
              color="error"
              fontSize="large"
              className={styles.error}
            />
            <h3>{message}</h3>
            <button
              onClick={() => {
                navigate(`/${shop}`);
              }}
              className={styles.button}
            >
              Voltar
            </button>
          </div>
        </div>
      ) : !loading ? (
        <div className={styles.box}>
          <Grid container>
            <Grid item xs={12}>
              <div className={styles.initialMessage}>
                <h2>Lista de Produtos</h2>
                <p>
                  {customer?.first_name} {customer?.last_name}, aqui está toda a
                  lista de produtos que você adquiriu nesse pedido.
                </p>
                <p>
                  <b>Selecione as peças que deseja trocar.</b>
                </p>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.select}>
                <FormGroup className={styles.divgeral}>
                  {products?.map((elem) => {
                    let filtered = elem.variants.filter((product) => {return product.variant.id === elem.variant_id})
                    filtered = filtered[0].variant
                    let items = [];
                    for (let i = 0; i < elem?.quantity; i++) {
                      items.push(
                        <div key={`${filtered?.sku}-${i}`} className={styles.option}>
                          <div key={filtered.id} className={styles.products}>
                            <div className={styles.infos}>
                              <div className={styles.item}>
                                <div className={styles.img}>
                                  {elem?.images[0]?.src ? (
                                    <img src={elem?.images[0]?.src} alt="img" />
                                  ) : (
                                    <Icon
                                      icon="icon-park-outline:ad-product"
                                      color="grey"
                                      width="75"
                                      height="82"
                                      style={{
                                        padding: "8px",
                                        marginRight: "20px",
                                      }}
                                    />
                                  )}
                                </div>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={10} sm={7}>
                                      <p>
                                        <b>
                                          {filtered?.title} - R${" "}
                                          {elem?.price?.toLocaleString(
                                            "pt-BR",
                                            {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            }
                                          )}
                                        </b>
                                      </p>
                                      {elem?.hasWarranty && (
                                        <Chip
                                          label="Em garantia"
                                          color="warning"
                                          size="small"
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={8} sm={5}>
                                      <div className={styles.selectAction}>
                                        <select
                                          id="action"
                                          fullWidth
                                          variant="outlined"
                                          defaultValue={"Manter"}
                                          className={styles.selectColor}
                                          value={
                                            selectedAction[`${filtered.sku}-${i}`]
                                          }
                                          onChange={(e) => {
                                            setSelectedAction({
                                              ...selectedAction,
                                              [`${filtered.sku}-${i}`]:
                                                e.target.value,
                                            });
                                            if (e.target.value === "Troca")
                                              setCredit({
                                                ...credit,
                                                [`${filtered.sku}-${i}`]: false,
                                              });
                                          }}
                                          // MenuProps={{keepMounted: true, disablePortal: true }}
                                          >
                                          <option value="Manter">
                                            Manter item
                                          </option>
                                          {(!order.deadLineDays && optionsChange?.length > 0) && (
                                            <option value="Troca">
                                              Trocar item
                                            </option>
                                          )}
                                          {(order.hasRefund && optionsDevolution?.length > 0) && (
                                            <option value="Devolução">
                                              Devolver item
                                            </option>
                                          )}
                                          {(elem.hasWarranty && optionsWarranty?.length > 0) && (
                                            <option value="Garantia">
                                              Acionar garantia
                                            </option>
                                          )}
                                        </select>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>

                          {selectedAction[`${filtered.sku}-${i}`] &&
                          selectedAction[`${filtered.sku}-${i}`] !== "Manter" ? (
                            <div className={styles.additional}>
                              <p className={styles.motivo}>Motivo</p>
                              <FormControl>
                                <select
                                  value={
                                    selectedReason[`${filtered.sku}-${i}`] || ""
                                  }
                                  required
                                  className={styles.comment}
                                  onChange={(e) =>
                                    setSelectedReason({
                                      ...selectedReason,
                                      [`${filtered.sku}-${i}`]: e.target.value,
                                    })
                                  }
                                >
                                  <option selected disabled value=''>
                                    Selecione o motivo
                                  </option>
                                  {selectedAction[`${filtered?.sku}-${i}`] ===
                                  "Troca"
                                    ? optionsChange?.map((item) => {
                                        return (
                                          <option key={item?.id} value={item}>
                                            {item}
                                          </option>
                                        );
                                      })
                                    : selectedAction[`${filtered?.sku}-${i}`] ===
                                      "Devolução"
                                    ? optionsDevolution?.map((item) => {
                                        return (
                                          <option key={item?.id} value={item}>
                                            {item}
                                          </option>
                                        );
                                      })
                                    : selectedAction[`${filtered?.sku}-${i}`] ===
                                      "Garantia"
                                    ? optionsWarranty?.map((item) => {
                                        return (
                                          <option key={item.id} value={item}>
                                            {item}
                                          </option>
                                        );
                                      })
                                    : ""}
                                </select>
                              </FormControl>

                              {selectedAction[`${filtered?.sku}-${i}`] === "Troca" &&
                              selectedReason[`${filtered?.sku}-${i}`] &&
                              !credit[`${filtered?.sku}-${i}`] ? (
                                elem?.variants?.length === 1 ? (
                                  ""
                                ) : (
                                  <div className={styles.change}>
                                    <p>
                                      <b>
                                        Escolha por qual produto você deseja
                                        trocar
                                      </b>
                                    </p>
                                    <Grid container spacing={2}>
                                      {generateSelects(
                                        elem.options,
                                        `${filtered.sku}-${i}`,
                                        elem.variants
                                      )}
                                    </Grid>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                              <div className={styles.checkBoxComments}>
                                <FormGroup>
                                  {selectedReason[`${filtered.sku}-${i}`] &&
                                  !credit[`${filtered.sku}-${i}`] &&
                                  selectedAction[`${filtered.sku}-${i}`] ===
                                    "Troca" ? (
                                    <div className={styles.helperText}>
                                      <p>
                                        <b>
                                          Caso não tenha encontrado a opção
                                          desejada, selecione essa opção.
                                        </b>
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {selectedAction[`${filtered.sku}-${i}`] !==
                                  "Garantia" ? (
                                    <FormControlLabel
                                      control={
                                        <Checkbox className={styles.checkBox} />
                                      }
                                      label="Receber vale-presente para trocar por outro produto."
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setCredit({
                                            ...credit,
                                            [`${filtered.sku}-${i}`]: true,
                                          });
                                          setChange({
                                            ...change,
                                            [`${filtered.sku}-${i}`]: "",
                                          });
                                        } else {
                                          setCredit({
                                            ...credit,
                                            [`${filtered.sku}-${i}`]: false,
                                          });
                                        }
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <p className={styles.motivo}>O que deseja da garantia</p>
                                      <select
                                        value={
                                          warrantyAction[`${filtered.sku}-${i}`] ||
                                          ""
                                        }
                                        required
                                        className={styles.comment}
                                        onChange={(e) =>
                                          setWarrantyAction({
                                            ...warrantyAction,
                                            [`${filtered.sku}-${i}`]:
                                              e.target.value,
                                          })
                                        }
                                      >
                                        {optionsChange?.length > 0 && (  
                                        <option
                                          key={"troca"}
                                          value={"Troca"}
                                        >
                                          Troca
                                        </option>
                                          )
                                        }
                                        <option
                                          key={"reparo"}
                                          value={"Reparo"}
                                        >
                                          Reparo
                                        </option>
                                        <option
                                          key={"reembolso"}
                                          value={"Reembolso"}
                                        >
                                          Reembolso
                                        </option>
                                      </select>
                                    </>
                                  )}
                                </FormGroup>
                                <p>
                                  Como podemos melhorar sua experiência?
                                  (Opcional)
                                </p>
                                <TextField
                                  fullWidth
                                  id="explication"
                                  variant="outlined"
                                  value={
                                    selectedExplication[`${filtered.sku}-${i}`]
                                  }
                                  onChange={(e) =>
                                    setSelectedExplication({
                                      ...selectedExplication,
                                      [`${filtered.sku}-${i}`]: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                    return items;
                  })}
                </FormGroup>
              </div>
              <button
                disabled={isButtonDisabled}
                onClick={() => {
                  if (
                    (hasDevolution &&
                    store?.bonusService?.statusBonus === 's')
                  ) {
                    navigate(`/coupon/${shop}/${name}`, {
                      state: { totalInfo },
                    });
                  } else if (
                    (hasDevolution &&
                    !store?.bonusService?.statusBonus === 'n') || (selectedProducts.some((product) => { return product.action === "Garantia" && product.actionWarranty === 'Reembolso'}))
                  ) {
                    navigate(`/refund/${shop}/${name}`, {
                      state: { totalInfo },
                    });
                  } else {
                    navigate(`/post/${shop}/${name}`, { state: { totalInfo } });
                  }
                  window.parent.postMessage("scroll", "*");
                }}
                className={
                  isButtonDisabled ? styles.buttonBlock : styles.button
                }
              >
                Continuar
              </button>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={styles.box}>
          <CircularProgress className={styles.loading} />
        </div>
      )}
    </Page>
  );
};

export default Products;
